import styled from "styled-components"

const familyReducer = ({ theme, ...rest }) => {
  switch (rest.font) {
    case "condensed":
      return theme.fontFamilyCondensed
    case "compressed":
      return theme.fontFamilyCompressed
    case "xCompressed":
      return theme.fontFamilyExtraCompressed
    default:
      return theme.fontFamilyRegular
  }
}

const weightReducer = ({ theme, ...rest }) => {
  switch (rest.weight) {
    case "book":
      return theme.fontWeightBook
    case "medium":
      return theme.fontWeightMedium
    case "bold":
      return theme.fontWeightBold
    case "black":
      return theme.fontWeightBlack
    default:
      return theme.fontWeightNormal
  }
}

const colorReducer = ({ theme, ...rest }) => {
  switch (rest.color) {
    case "yellow":
      return theme.fontColorYellow
    case "darkGrey":
      return theme.fontColorDarkGrey
    case "bgLight":
      return theme.bgColorLight
    default:
      return theme.fontColorWhite
  }
}

const sizeReducer = ({ theme, ...rest }) => {
  switch (rest.size) {
    case "small":
      return theme.fontSizeSmall
    case "medium":
      return theme.fontSizeMedium
    case "large":
      return theme.fontSizeLarge
    case "xl":
      return theme.fontSizeXL
    case "xxl":
      return theme.fontSize2XL
    case "xxxl":
      return theme.fontSize3XL
    case "giant":
      return theme.fontSizeGiant
    default:
      return theme.fontSizeNormal
  }
}

const Text = styled.span`
  color: ${colorReducer};
  font-family: ${familyReducer};
  font-size: ${sizeReducer};
  font-weight: ${weightReducer};
  line-height: 1.563em;
  margin: 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-transform: ${p => (p.uppercase ? "uppercase" : "none")};
`

export default Text
