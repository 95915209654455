import { Link } from "gatsby"
import React, { useState } from "react"
import styled, { css } from "styled-components"
import Text from "../text/text"

const NavBarContainer = styled.div`
  cursor: pointer;
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  background: ${p => p.theme.bgColorDark};
  z-index: 200;
`

const NavBarContent = styled.div`
  background: ${p => p.theme.fontColorYellow};
  padding: 1rem;
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  ${p => (p.expanded ? "box-shadow: 0 6px 8px 0 rgba(0, 0, 0, 0.5);" : "")}
  ${p => (p.expanded ? "cursor: initial;" : "cursor: pointer;")}
  ${p =>
    p.expanded
      ? `background: ${p => p.theme.bgColorYellow};`
      : `&:hover {background: #F3D143;}`}
`

const NavLink = styled(Link)`
  cursor: pointer;
  text-decoration: none;
  display: block;
  padding-left: 2rem;
  margin-bottom: 0.5em;
  & > ${Text}:hover {
    color: ${p => p.theme.fontColorWhite};
  }
`

const NavExternalLink = styled.a`
  cursor: pointer;
  text-decoration: none;
  display: block;
  padding-left: 2rem;

  & > ${Text}:hover {
    color: ${p => p.theme.fontColorWhite};
  }
`

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  & > ${Text}:hover {
    color: ${p => p.theme.fontColorWhite};
  }
`

const HamburgerLine = styled.div`
  height: 3px;
  background: ${p => p.theme.bgColorDark};
  width: 30px;
  margin: 4px 0;
  margin-right: 8px;
  transition: all 0.25s ease-in-out;
  transform-origin: 35% 50%;
`

const Hamburger = styled.div`
  ${HamburgerLine} {
    &:nth-child(2) {
      width: 20px;
    }
  }
  ${p => {
    if (p.expanded) {
      return css`
        ${HamburgerLine} {
          &:nth-child(1) {
            transform: rotate(45deg);
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            transform: rotate(-45deg);
          }
        }
      `
    }
  }}
`

const BottomContent = styled.div`
  display: ${p => (p.expanded ? "block" : "none")};
  margin-top: 1.5rem;
`

const NavBar = () => {
  const [expanded, setExpanded] = useState(false)

  return (
    <NavBarContainer>
      <NavBarContent
        expanded={expanded}
        onClick={() => (expanded ? null : setExpanded(!expanded))}
      >
        <TextWrapper expanded={expanded} onClick={() => setExpanded(!expanded)}>
          <Hamburger expanded={expanded}>
            <HamburgerLine />
            <HamburgerLine />
            <HamburgerLine />
          </Hamburger>
          <Text color="bgLight" weight="bold">
            {expanded ? "close" : "menu"}
          </Text>
        </TextWrapper>
        <BottomContent expanded={expanded}>
          <NavLink to="/">
            <Text color="bgLight" weight="bold">
              Home
            </Text>
          </NavLink>
          <NavLink to="/our-research">
            <Text color="bgLight" weight="bold">
              Our Research
            </Text>
          </NavLink>
          <NavLink to="/flaring-emissions">
            <Text color="bgLight" weight="bold">
              Flaring Emissions
            </Text>
          </NavLink>
          <NavExternalLink
            href="https://www.edf.org/climate/oil-and-gas"
            target="_blank"
            rel="noopener"
          >
            <Text color="bgLight" weight="bold">
              About EDF
            </Text>
          </NavExternalLink>
        </BottomContent>
      </NavBarContent>
    </NavBarContainer>
  )
}

export default NavBar
