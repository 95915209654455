import React from "react"
import PropTypes from "prop-types"
import { StaticQuery, graphql } from "gatsby"
import { ThemeProvider } from "styled-components"
import NavBar from "../../components/elements/layout/navbar"

import SEO from "./seo"
import "./layout.css"

const mainTheme = {
  bgColorLight: "#2F3133",
  bgColorDark: "#252729",
  bgColorMedium: "#3B3F43",
  bgColorTransparent: "transparent",
  bgColorYellow: "#F4C70B",
  bgColorYellowHover: "#F3D143",

  borderPrimary: "1px solid #F4C70B",
  borderPrimaryGrey: "1px solid #252729",
  borderSecondary: "none",

  fontFamilyRegular: "benton-sans, sans-serif",
  fontFamilyCompressed: "benton-sans-compressed, sans-serif",
  fontFamilyExtraCompressed: "benton-sans-extra-compressed, sans-serif",
  fontFamilyCondensed: "benton-sans-condensed, sans-serif",

  fontColorYellow: "#F4C70B",
  fontColorYellowHover: "#DBAE00",
  fontColorWhite: "#FFFFFF",
  fontColorWhiteReduced: "#909192",
  fontColorGrey: "#313131",
  fontColorDarkGrey: "#1A1B1D",

  fontSizeSmall: "0.875rem", // ~14px
  fontSizeNormal: "1rem", // ~16px
  fontSizeMedium: "1.375rem", // ~22px
  fontSizeLarge: "1.5rem", // ~24px
  fontSizeXL: "1.75rem", // ~28px
  fontSize2XL: "2.0625rem", // ~33px
  fontSize3XL: "2.5rem", // ~40px
  fontSizeGiant: "9.375rem", // ~150px

  fontWeightBook: 400,
  fontWeightNormal: 500,
  fontWeightMedium: 600,
  fontWeightBold: 700,
  fontWeightBlack: 800,

  imageWidthSquare: "21.875em", // ~350px
  imageHeightSquare: "17.188em", // ~275px
  imageWidthThin: "10.000em", // ~160px
  imageHeightThin: "17.188em", // ~275px
  imageWidthLong: "28.125em", // ~160px
  imageHeightLong: "17.188em", // ~275px
  imageWidthWide: "23.125em", // ~160px
  imageHeightWide: "17.188em", // ~275px
  imageWidthMobile: "21.875em", // ~160px
  imageHeightMobile: "9.000em", // ~275px
  imageWidthStripesSquare: "10.625em", // ~170px
  imageHeightStripesSquare: "12.375em", // ~198px
  imageWidthStripesThin: "4.188em", // ~67px
  imageHeightStripesThin: "17.188em", // ~275px
  imageWidthStripesSquareShort: "10.625em", // ~170px
  imageHeightStripesSquareShort: "10.375em", // ~138px

  boxWidthMobile: "21.875em", // ~350px
  boxHeightMobile: "10.625em", // ~127px
  boxWidthSmall: "10.625em", // ~170px
  boxHeightSmall: "3.750em", // ~60px
  boxWidthMedium: "21.875em", // ~350px
  boxHeightMedium: "17.188em", // ~275px
  boxWidthLarge: "28.438em", // ~455px
  boxHeightLarge: "17.188em", // ~275px

  boxMarginTopMobile: "0", // ~0px
  boxMarginTopSmall: "0", // ~0px

  boxPaddingMobile: "0 1.406em", // ~0px
  boxPaddingSmall: "0", // ~0px
  boxPaddingMedium: "0 1.406em", // ~22.5px
  boxPaddingLarge: "0 2.938em", // ~47px
}

const Layout = ({ children }) => {
  return (
    <StaticQuery
      query={graphql`
        query SiteTitleQuery {
          site {
            siteMetadata {
              title
            }
          }
        }
      `}
      render={() => (
        <>
          <ThemeProvider theme={mainTheme}>
            <SEO />
            <NavBar />
            {children}
          </ThemeProvider>
        </>
      )}
    />
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
